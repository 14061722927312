<template>
  <div class="setup">
    <h2>Hey Arthur</h2>
    <h1>Select a warrior</h1>
    <div class="warriors">
      <div class="row row1">
        <div class="king warrior"><img src="/img/characters/girl.svg" alt="" /></div>
        <div class="mage warrior"><img src="/img/characters/boy.svg" alt="" /></div>
        <div class="dude warrior"><img   src="/img/characters/girl.svg" alt="" /></div>
        <div class="weirdo warrior"><img src="/img/characters/boy.svg"  alt="" /></div>
      </div>
      <!-- <div class="row row2">
        <div class="dude warrior"><img   src="/img/characters/girl.svg" alt="" /></div>
        <div class="weirdo warrior"><img src="/img/characters/boy.svg"  alt="" /></div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "setup",
  data: function () {
    return {
      warriors: "not updated",
    };
  },
  methods: {
    plus: function () {
      this.a++;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.warriors {
  padding-left: 15%;
  .row {
    display: flex;
    &.row2 {
      padding-left: 8%;
    }
    .warrior {
      margin-right: 5%;
      cursor: pointer;
      img {
        // width: 50%;
        height:60vh;
      }
      &:hover {
        img {
          border: 1px dashed salmon;
        }
      }
    }
  }
}
</style>
