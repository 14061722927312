<template>
  <div class="leagues">
    <!-- <h1>Map/Leagues</h1>
    <div class="map">
      <div class="category">2D</div>
      <div class="category">Championship</div>
      <div class="category">3D</div>
      <div class="category">Paint</div>
      <div class="category">Photo</div>
    </div> -->

    <div class="conway-wrap">
      <button @click="play">play</button>
      <button @click="clear">clear</button>
      <div class="grid">
        <div class="row" v-for="(row, i) in grid" :key="i">
          <span v-for="(cell, j) in row" :key="j">
            <div
              :class="`cell
               ${cell.alive ? 'alive' : 'dead'}`"
              @click="cellToggle(i, j)"
            >
              {{ cell.count }}
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "setup",
  data: function () {
    return {
      warriors: "not updated",
      grid: [],
      size: 10,
      tick: [],
    };
  },
  mounted: function () {
    this.gridGen();
  },
  methods: {
    showBtn: function (row, cell, i, j) {
      if (i === 0) {
        return false;
      } else if (i === row.length) {
        return false;
      } else if (j === 0) {
        return false;
      } else if (j === row.length) {
        return false;
      } else {
        return true;
      }
    },
    play: function () {
      this.phase();

      // this.tick = setInterval(
      //   function () {
      //     this.phase();
      //   }.bind(this),
      //   500
      // );
    },
    phase: function () {
      for (let i = 0; i < this.grid.length; i++) {
        for (let j = 0; j < this.grid[i].length; j++) {
          this.surroundCheck(i, j);
        }
      }
      // console.log(this.grid);
      this.eliminateAndBirth();
    },
    surroundCheck: function (row, cell) {
      let grid = this.grid;
      let cellAlive = grid[row][cell].alive;
      let neighbors = [];
      if (cellAlive) {
        if (row <= this.size && cell <= this.size) {
          for (let i = -1; i < 2; i++) {
            for (let j = -1; j < 2; j++) {
              let checkI = row + i;
              let checkJ = cell + j;
              // console.log(checkI);
              // console.log(checkJ);
              if (
                (i !== 0 || j !== 0) &&
                checkI >= 0 &&
                checkI < this.size &&
                checkJ >= 0 &&
                checkJ < this.size
              ) {
                grid[row][cell].count++;
              }
            }
          }
        }
      }

      // if (cellAlive && row === 0 && cell === 0) {
      //   //top left
      //   neighbors = [
      //     grid[row][cell + 1], // right
      //     grid[row + 1][cell], // bottom
      //     grid[row + 1][cell + 1], // bottomRight
      //   ];
      // }
      // if (cellAlive && row === 0 && cell === 0) {
      //   //top
      //   neighbors = [
      //     grid[row][cell + 1], // right
      //     grid[row + 1][cell], // bottom
      //     grid[row + 1][cell + 1], // bottomRight
      //   ];
      // }
      // if (cellAlive && row === 0 && cell === this.size.lenght) {
      //   //top right
      //   neighbors = [
      //     grid[row][cell - 1], // left
      //     grid[row + 1][cell - 1], // bottomLeft
      //     grid[row + 1][cell], // bottom
      //   ];
      // }
      // if (cellAlive) {
      //   // if (
      //   //   grid[row - 1][cell - 1] === undefined ||
      //   //   grid[row - 1][cell - 1] == undefined
      //   // ) {
      //   // }
      //   let cell1 = () => {
      //     return this.grid[row - 1][cell - 1];
      //   };
      //   console.log(cell1());
      //   // console.log(this.grid[row - 1][cell - 1]);
      //   // typeof grid[row - 1][cell - 1] === undefined
      //   //   ? alert("myObj is undefined")
      //   //   : null; // topLeft
      //   // typeof grid[row - 1][cell] ? grid[row - 1][cell] : null; // top
      //   // typeof grid[row - 1][cell + 1] ? grid[row - 1][cell] : null; // left
      //   // typeof grid[row][cell - 1] ? grid[row][cell - 1] : null; // left
      //   // typeof grid[row][cell + 1] ? grid[row][cell + 1] : null; // right
      //   // typeof grid[row + 1][cell - 1] ? rid[row + 1][cell - 1] : null; // bottomLeft
      //   // typeof grid[row + 1][cell] ? grid[row + 1][cell] : null; // bottom
      //   // typeof grid[row + 1][cell + 1] ? grid[row + 1][cell + 1] : null; // bottomRight
      //   // neighbors = [
      //   // ];
      // }
      // for (let i = 0; i < neighbors.length; i++) {
      //   const neighbor = neighbors[i];
      //   neighbor.count++;
      // }
    },
    eliminateAndBirth: function () {
      for (let i = 0; i < this.grid.length; i++) {
        const row = this.grid[i];
        for (let j = 0; j < row.length; j++) {
          const cell = row[j];
          if (cell.alive && cell.count === 2) {
            cell.alive = true;
          } else if (!cell.alive && cell.count === 3) {
            cell.alive = true;
          } else {
            cell.alive = false;
          }
          cell.count = 0;
        }
      }
    },
    cellToggle: function (row, cell) {
      this.grid[row][cell].alive = !this.grid[row][cell].alive;
    },
    clear: function () {
      clearInterval(this.tick);
      this.grid = [];
      this.gridGen();
    },
    gridGen: function () {
      // generates grid
      for (let i = 0; i < this.size; i++) {
        var row = []; // row in grid
        for (let j = 0; j < this.size; j++) {
          row.push({ alive: false, count: 0 });
        }
        this.grid.push(row);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.leagues {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  button {
    color: green;
    margin: 0;
    padding: 0;
  }
  span {
  }
}
.grid {
  // width: 100px;
  // height: 100px;
}
.row {
  display: flex;
  .cell {
    width: 20px;
    height: 20px;
    &.dead {
      border: 1px solid black;
    }
    &.alive {
      background: black;
      border: 1px solid black;
    }
  }
}
</style>
