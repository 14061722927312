<template>
  <div class="dojo">
    <nav></nav>
    {{ jwt }}
    <h1>Dojo</h1>
    <div class="progress-wrap">
      <label for="level">Level 1&nbsp;</label>
      <progress id="level" value="32" max="100">32%</progress>
    </div>
    <div class="center-wrap">
      <div class="warrior"><img src="/img/characters/boy.svg" alt="" /></div>
      <div class="menu">
        <button @click="signIn">Wars</button>
        <button>Acheivements</button>
        <button>Gallery</button>
        <button>Portfolio</button>
      </div>
    </div>
    <div class="awards">
      <h3>Awards:</h3>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "setup",
  data: function () {
    return {
      warriors: "not updated",
    };
  },
  mounted: () => {},
  data: function () {
    return {
      jwt: "not updated",
    };
  },
  methods: {
    plus() {
      this.a++;
    },
    async signIn(email, password) {
      const { data } = await axios.post("http://localhost:1337/auth/local", {
        identifier: "cariel226@gmail.com",
        password: "ilovejunk",
      });

      console.log(data);
      this.jwt = data.jwt;
    },
    async update(email, password) {
      const { data } = await axios.put("http://localhost:1337/auth/local", {
        identifier: "cariel226@gmail.com",
        password: "ilovejunk",
      });

      console.log(data);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
nav {
  position: fixed;
  height: 5vh;
  width: 100%;
  top: 0;
  left: 0;
  background: url("/img/dojo/dojo-nav.png");
}
.progress-wrap {
  text-align: center;
  label {
    margin-right: 20px;
  }
}
.center-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  .menu {
    display: flex;
    flex-direction: column;
    width: 40%;
    button {
      cursor: pointer;
      margin-bottom: 30px;
      line-height: 30px;
    }
  }
}
.warrior {
  img {
    height: 60vh;
  }
}
.awards {
  border-top: black 1px dashed;
}
</style>
